<template>
  <div class="zee-container">
    <zee-toast v-if="toastData.show" :type="toastData.type" :message="toastData.message" :show="toastData.show"
      :time="10000" @clearToast="clearToast" />
    <div class="zee-container-wrapper" v-if="showBlock === 'screen_1'">
      <zee-screen-title :title="'Output'" :route="'/solutions/zeetv/program-plans'">
        <zee-dropdown style="width: 300px" :options="outputDropdown" :selectedOption="selectedOutputOption"
          :placeHolder="'Select'" @input="handleDropdownChange($event)" />
      </zee-screen-title>
      <div class="zee-card-background">
        <div class="zee-output-table-align">
          <zee-table :tableHeaderData="mainHeader" />
          <!-- <zee-output-table :tableHeaderData="tableHeaderData"/> -->
          <zee-output-table :tableHeaderData="tableHeaderData" :carouselData="carouselData1"
            :carouselData3Color="carouselData1Color" @handleDataChange="handleDataChange('screen_1')"
            @handleBackDataChange="handleBackDataChange()" />
        </div>
        <div class="zee-table-card-background">
          <!-- <details>
            <summary>Upcoming Program</summary>
              <div class="d-flex justify-content-between"> -->
          <zee-table :tableBodyData="upcommingProgramData" />
          <zee-output-table :tableBodyData="upcommingProgramCustomData" />
          <!-- </div>
          </details> -->
        </div>
        <div :style="{ 'background-color': '#f3f3f3' }">
          <div class="zee-table-card-background" v-for="(item, i) in otherProgramTableData" :key="i">
            <zee-table :tableBodyData="item" />
            <zee-output-table :tableBodyData="otherProgramTableBodyData[i]" />
            <!-- {{otherProgramTableBodyData[i]}} -->
          </div>
        </div>
        <div class="d-none justify-content-end mt-4">
          <div class="zee-output-summary-tab-total">Total</div>
          <div class="zee-output-summary-tab">
            <div class="zee-totalContentWrapper" :style="{ width: 474 + 'px' }">
              <div class="zee-totalContent" v-for="i in 3" :key="i">{{ i }}</div>
            </div>
          </div>
        </div>
        <!-- <div class="zee-table-card-background">
        <zee-table :tableHeaderData="mainHeader" />
        <zee-output-table :tableHeaderData="tableHeaderData1" />
      </div> -->
        <div class="zee-output-table-buttons mt-5">
          <zee-button class="d-none" style="width: 200px; background-color: #6db056" :title="'Download Outputs'"
            @onClick="downloadOutputTemplate" />
          <zee-button class="d-none" :title="'Go to Weekly Outputs'" style="width: 250px; background-color: #6db056"
            @onClick="handleNext" />
        </div>
      </div>
    </div>

    <div class="zee-container-wrapper" v-if="showBlock === 'screen_2'">
      <zee-screen-title :title="'Output'" :route="`${this.outputSummaryPraramData}`" @back-event="handleBack">
        <zee-dropdown style="width: 300px" :options="outputDropdown" :selectedOption="selectedOutputOption"
          :placeHolder="'Select'" @input="handleDropdownChange($event)" />
      </zee-screen-title>
      <div class="zee-card-background">
        <div class="zee-output-table-align">
          <zee-output-table :tableHeaderData="mainHeader2" />
          <zee-output-table :tableHeaderData="tableHeaderData2" />
        </div>
        <!-- {{this.mainHeader2}} -->
        <div class="zee-table-card-background">
          <zee-table :tableBodyData="upcommingProgramData2" />
          <zee-output-table :tableBodyData="upcommingProgramCustomData1" />
        </div>
        <div class="d-flex justify-content-end mt-4">
          <div class="zee-output-summary-tab-total">Total</div>
          <div class="zee-output-summary-tab">
            <div class="zee-totalContentWrapper" :style="{ width: this.tableBodyWidth + 'px' }">
              <div class="zee-totalContent" v-for="(item, i) in totalSecondagesWeekly" :key="i">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="zee-table-card-background">
        <zee-table :tableHeaderData="mainHeader" />
        <zee-output-table :tableHeaderData="tableHeaderData1" />
      </div> -->
        <div class="zee-output-table-buttons d-none justify-content-end mt-5">
          <!-- <zee-button :title="'Back'" @onClick="handleBack" /> -->
          <!-- <zee-button
            :title="'Download Date Wise Summary'"
            style="width: 17%; background-color: #6db056"
            @onClick="downloadDailyTemplate"
          /> -->
          <zee-button :title="'Download'" @onClick="showProgramOutputDownloadModal = true"
            style="width: 17%; background-color: #6db056" />
          <!-- <zee-button
            :title="'Approve Plan'"
            style="width: 150px"
            @onClick="handleNext"
          /> -->
        </div>
      </div>
      <!-- <div style="width: 100%">
        <output-summary />
      </div> -->
    </div>

    <div class="zee-container-wrapper" v-if="showBlock === 'screen_3'">
      <zee-screen-title :title="'Output'" :route="''" @back-event="handleBack">
        <zee-dropdown style="width: 300px" :options="outputDropdown" :selectedOption="selectedOutputOption"
          :placeHolder="'Select'" @input="handleDropdownChange($event)" />
      </zee-screen-title>
      <div class="zee-card-background">
        <div class="zee-output-table-align">
          <zee-output-table :tableHeaderData="mainHeader2" />
          <!-- <div v-for="(carouselData, i) in carouselData3" :key="i"> -->
          <zee-output-table :tableHeaderData="tableHeaderData3" :carouselData="carouselData3"
            :carouselData3Color="carouselData3Color" @handleDataChange="handleDataChange()"
            @handleBackDataChange="handleBackDataChange()" />
          <!-- AADD THEE -->
          <!-- </div> -->
        </div>
        <div class="zee-table-card-background">
          <zee-table :tableBodyData="upcommingProgramData2" />
          <zee-output-table :tableBodyData="upcommingProgramCustomData2" />
        </div>
        <div class="d-none justify-content-end mt-4">
          <div class="zee-output-summary-tab-total">Total</div>
          <div class="zee-output-summary-tab">
            <div class="zee-totalContentWrapper" :style="{ width: this.tableBodyWidth + 'px' }">
              <div class="zee-totalContent" v-for="(item, i) in upcommingProgramCustomData2[0].columns" :key="i">
                {{ i }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="zee-table-card-background">
        <zee-table :tableHeaderData="mainHeader" />
        <zee-output-table :tableHeaderData="tableHeaderData1" />
      </div> -->
        <div class="zee-output-table-buttons mt-5">
          <!-- <zee-button :title="'Back'" @onClick="handleBack" /> -->
          <zee-button :title="'Download124'" style="width: 150px; background-color: #6db056"
            @onClick="downloadDailyTemplate" />
        </div>
      </div>
    </div>

    <div class="zee-container-wrapper" v-if="showBlock === 'screen_4'">
      <zee-screen-title :title="'Output'" :route="''" @back-event="handleBack">
        <zee-dropdown style="width: 300px" :options="outputDropdown" :selectedOption="selectedOutputOption"
          :placeHolder="'Select'" @input="handleDropdownChange($event)" />
      </zee-screen-title>
      <div class="zee-card-background">
        <!-- <div class="d-flex justify-content-end mt-4" style="padding-left:4.4%;">
        <div class="zee-table-header">
        <div class="zee-table-header-data" v-for="(item,i) in topHeader" :key="i">
          {{item}}
        </div>
        </div>
      </div> -->
        <div class="zee-output-table-align">
          <zee-output-table :tableHeaderData="mainHeader2" />
          <div class="d-flex justify-content-center align-items-center mt-4">
            <div v-for="i in 4" :key="i">
              <zee-output-table :tableHeaderData="tableHeaderData4" :tableTopHeaderData="topHeader" />
            </div>
          </div>
          <!-- <zee-output-table :tableHeaderData="tableHeaderData4" :tableTopHeaderData="topHeader"/> -->
        </div>
        <div class="zee-table-card-background">
          <zee-table :tableBodyData="upcommingProgramData2" />
          <zee-output-table :tableBodyData="upcommingProgramCustomData4" />
        </div>
        <div class="d-none justify-content-end mt-4">
          <div class="zee-output-summary-tab-total">Total</div>
          <div class="zee-output-summary-tab">
            <div class="zee-totalContentWrapper" :style="{ width: this.tableBodyWidth + 'px' }">
              <div class="zee-totalContent" v-for="(item, i) in upcommingProgramCustomData4[0].columns" :key="i">
                {{ i }}
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="zee-table-card-background">
        <zee-table :tableHeaderData="mainHeader" />
        <zee-output-table :tableHeaderData="tableHeaderData1" />
      </div> -->
        <div class="zee-output-table-buttons mt-5">
          <!-- <zee-button :title="'Back'" @onClick="handleBack" /> -->
          <zee-button :title="'Download Output'" style="width: 150px; background-color: #6db056"
            @onClick="downloadDailyTemplate" />
        </div>
      </div>
    </div>

    <div class="zee-container-wrapper" v-if="showBlock === 'screen_5'">
      <zee-screen-title :title="'Output'" :route="''" @back-event="handleBack">
        <zee-dropdown style="width: 300px" :options="outputDropdown" :selectedOption="selectedOutputOption"
          :placeHolder="'Select'" @input="handleDropdownChange($event)" />
      </zee-screen-title>
      <div class="zee-card-background">
        <div class="zee-output-table-align">
          <div>
            <zee-output-table :tableHeaderData="mainHeader2" />
          </div>
          <div v-for="(carouselData, i) in carouselData5" :key="i">
            <zee-output-table :tableHeaderData="tableHeaderData5" :carouselData="carouselData" />
          </div>
        </div>
        <div class="zee-table-card-background">
          <div>
            <zee-table :tableBodyData="upcommingProgramData2" />
          </div>
          <div v-for="(upcommingProgramCustomData4, i) in upcommingProgramCustomData5" :key="i">
            <div v-for="(upcommingProgramCustomData, i) in upcommingProgramCustomData4" :key="i">
              <zee-output-table :tableBodyData="upcommingProgramCustomData" />
            </div>
          </div>
        </div>
        <div class="d-none justify-content-end mt-4">
          <div class="zee-output-summary-tab-total">Total</div>
          <div class="zee-output-summary-tab">
            <div class="zee-totalContentWrapper" :style="{ width: this.tableBodyWidth * 3 + 'px' }">
              <div class="zee-totalContent" v-for="i in 9" :key="i">{{ i }}</div>
            </div>
          </div>
        </div>
        <!-- <div class="zee-table-card-background">
        <zee-table :tableHeaderData="mainHeader" />
        <zee-output-table :tableHeaderData="tableHeaderData1" />
      </div> -->
        <div class="zee-output-table-buttons mt-5">
          <!-- <zee-button :title="'Back'" @onClick="handleBack" /> -->
          <zee-button :title="'Download Output'" @onClick="downloadWeeklyTemplate"
            style="width: 150px; background-color: #6db056" />
        </div>
      </div>
    </div>

    <div class="zee-output-program-container">
      <table class="zee-output-program-table">
        <thead>
          <tr>
            <th>
              <span class="zee-output-program-table-header">Format Type</span>
            </th>

            <th>
              <span class="zee-output-program-table-header">Action</span>
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(item, index) in programOutputData.columns" :key="index" valign="top">
            <td>
              <span class="zee-output-program-table-body" :title="item.planName">{{
                  item.planName
              }}</span>
            </td>
            <!-- <td>
              <span class="zee-output-program-table-body" :title="item.progress">{{
                item.progress
              }}</span>
            </td>
            <td>
              <span class="zee-output-program-table-body" :title="item.status">{{
                item.status
              }}</span>
            </td> -->
            <td>
              <button class="zee-output-program-table-btn" style="color: #000" @click="downloadTemplates(item)">
                Download
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="zee-output-table-buttons mt-5">
      <!-- <zee-button :title="'Back'" @onClick="handleBack" /> -->
      <zee-button :title="'View all saved plans'" @onClick="viewAllOutputs"
        style="width: 100%; background-color: #6db056" />
    </div>
    <program-output-download-modal :modalShow="showProgramOutputDownloadModal"
      @closeModal="showProgramOutputDownloadModal = false" />
  </div>
  <!-- 
  </div> -->
  <!-- <div> -->
  <!-- <zee-output-table
      :tableHeaderData="tableHeaderData"
      :tableBodyData="tableBodyData"
      :headerTableLeftIcon="'angle-left'"
      :headerTableRightIcon="'angle-right'"
    /> -->
  <!-- </div> -->
</template>

<script>
import {
  ScreenTitle,
  Dropdown,
  Table,
  OutputTable,
  Button,
  ProgramOutputDownloadModal,
} from "../components";
import Toast from "@/components/Toast/Toast.vue";
// import OutputSummary from "../pages/OutputSummary";
import { zeeTvSolutions } from "../services/zeeTvSolutions";
const zeeSolutions = new zeeTvSolutions();
// import Icon from "vue-awesome/components/Icon";
// import "vue-awesome/icons";
export default {
  name: "ProgramOutput",
  components: {
    "zee-screen-title": ScreenTitle,
    "zee-dropdown": Dropdown,
    "zee-table": Table,
    "zee-output-table": OutputTable,
    "zee-button": Button,
    "zee-toast": Toast,
    "program-output-download-modal": ProgramOutputDownloadModal,
    // "output-summary": OutputSummary,
    // "zee-icon":Icon

    // "zee-output-table": OutputTable,
  },
  updated() {
    this.calculateWidth();
  },
  created() {
    if (this.$route.params.data === "output-summary") {
      this.outputSummaryPraramData = "/solutions/zeetv/program-outputs";
      sessionStorage.setItem("backFromOutputs", true);


    } else {
      this.outputSummaryPraramData = "/solutions/zeetv/program-plans";
      sessionStorage.setItem("backFromOutputs", true);

    }
    this.getAPIData();
    this.postSavePlan();
  },
  methods: {
    downloadTemplates(e) {
      console.log(e);
      let x = e.planName.replace(" ", "");
      let y = x.replace(" ", "");
      console.log(x);
      let run_id = sessionStorage.getItem("run_id");
      zeeSolutions
        .getDownloadWeeklySummary(run_id, e.planName, y)
        .then(() => { })
        .catch((err) => {
          alert(err);
        });
    },
    viewAllOutputs() {
      this.$router.push(`/solutions/zeetv/program-outputs`);
    },
    postSavePlan() {
      let runId = sessionStorage.getItem("run_id");
      // let clientId = (Math.random() + 1).toString(36).substring(7);
      let clientId = sessionStorage.getItem("clientId");

      let planName = sessionStorage.getItem("userEnteredPlanName");

      if (planName != null) {
        zeeSolutions
          .postUniversalDownload(runId, clientId, planName)
          .then(() => {
            sessionStorage.setItem("client_generated_id", clientId);
          })
          .catch((err) => {
            this.$router.push("/solutions/zeetv/program-output")
            alert(err);
          });
      }
    },
    downloadDailyTemplate() {
      let runId = sessionStorage.getItem("run_id");
      zeeSolutions.downloadDailyTemplate(runId).then(() => { });
    },
    clearToast() {
      this.toastData = {
        show: false,
        type: "",
        message: "",
      };
    },
    calculateWidth() {
      this.tableBodyWidth = document.getElementById("zeeOutputBody").clientWidth;
    },
    downloadWeeklyTemplate() {
      let runId = sessionStorage.getItem("run_id");
      this.toastData = {
        show: true,
        type: "success",
        message: "Download Started",
      };
      zeeSolutions.downloadWeeklyTemplate(runId).then(() => { });
    },
    // downloadOutputTemplate() {
    //   let runId = sessionStorage.getItem("run_id");
    //   this.toastData = {
    //     show: true,
    //     type: "success",
    //     message: "Download Started",
    //   };
    //   zeeSolutions.downloadOutputTemplate(runId).then(() => {});
    // },
    handleNext() {
      switch (this.showBlock) {
        case "screen_1":
          this.showBlock = "screen_2";
          break;
        case "screen_2":
          // this.showBlock = "screen_3";
          this.toastData = {
            show: true,
            type: "edit",
            message: "Data taking time to load, please download the data template",
          };
          break;
        case "screen_3":
          this.showBlock = "screen_4";
          break;
        case "screen_4":
          this.showBlock = "screen_5";
          break;
        case "screen_5":
          this.$router.push("/solutions/zeetv/program-launch");
          break;
        default:
          break;
      }
    },
    handleBack() {
      let current = this.showBlock;
      switch (current) {
        case "screen_1":
          console.log("");
          break;
        case "screen_2":
          this.showBlock = "screen_1";
          break;
        case "screen_3":
          this.showBlock = "screen_2";
          break;
        case "screen_4":
          this.showBlock = "screen_2";
          break;
        case "screen_5":
          this.showBlock = "screen_4";
          break;
        default:
          break;
      }
      // this.showBlock = "screen_1";
    },
    handleDataChange(val) {
      // console.log("In program outout screen for data change");
      // let cda = ['Week 2', 'Week 3', 'Week 4']
      // let cdac = ['#ff94b7','#ffc107','#6db056']
      if (val === "screen_1") {
        // console.log("in the screen_1");
        this.count1 += 1;
        if (this.count1 >= this.numberOfWeeks - 1) {
          this.count1 = -1;
          this.carouselData1 = "Week 1";
          this.carouselData1Color = "#fffba7";
          this.upcommingProgramCustomData = [
            {
              columns: [
                { id: 0, name: this.apiData["0"].secondages, color: "#fffba7" },
                {
                  id: 0,
                  name: Math.round(+this.apiData["0"].assignedGRP),
                  color: "#fffba7",
                },
                { id: 0, name: this.apiData["0"].grpTarget, color: "#fffba7" },
              ],
            },
          ];
          for (let i = 0; i < this.otherProgramsData.length; i++) {
            this.otherProgramTableBodyData[i] = [
              {
                columns: [
                  {
                    id: 0,
                    name: this.otherProgramsData[i][0].secondages,
                    color: "#fffba7",
                  },
                  {
                    id: 1,
                    name: Math.round(+this.otherProgramsData[i][0].assignedGRP),
                    color: "#fffba7",
                  },
                  {
                    id: 2,
                    name: this.otherProgramsData[i][0].grpTarget,
                    color: "#fffba7",
                  },
                ],
              },
            ];
          }
          this.tableHeaderData = [
            {
              columns: [
                { id: 0, name: "Secondage", color: "#fffba7" },
                { id: 0, name: "Assigned GRPs", color: "#fffba7" },
                { id: 0, name: "Planned GRPs", color: "#fffba7" },
              ],
            },
          ];
          return;
        }
        // this.carouselData1 = this.cda[this.count1];
        this.carouselData1 = `Week ${this.count1 + 2}`;
        this.carouselData1Color = this.cdac[this.count1];
        this.upcommingProgramCustomData = this.upcd[this.count1];
        this.tableHeaderData = this.tbhd[this.count1];
        for (let i = 0; i < this.otherProgramsData.length; i++) {
          this.otherProgramTableBodyData[i] = this.opcdb[i][this.count1];
          // console.log("other data ", this.opcdb[i][this.count1]);
        }
        // console.log("other data ", this.otherProgramTableBodyData);

        // for(let i = 0; i<this.otherProgramsData.length; i++){

        // }

        return;
      }
      this.count += 1;
      if (this.count >= this.cda.length) {
        this.count = -1;
        this.carouselData3 = "Week 1";
        this.carouselData3Color = "#fffba7";
        this.upcommingProgramCustomData2 = [
          {
            columns: [
              { id: 0, name: "", color: "#fffba7", notYetIntegrated: true },
              { id: 0, name: "", color: "#fffba7" },
              { id: 0, name: "", color: "#fffba7" },
              { id: 0, name: "", color: "#fffba7" },
              { id: 0, name: "", color: "#fffba7" },
              { id: 0, name: "", color: "#fffba7" },
              { id: 0, name: "", color: "#fffba7" },
              { id: 0, name: "", color: "#fffba7" },
              // { id: 0, name: " ", color: "grey" },
              // { id: 0, name: " ", color: "grey" },
              // { id: 0, name: " ", color: "grey" },
              // { id: 0, name: " ", color: "grey" },
            ],
          },
          {
            columns: [
              { id: 1, name: "", color: "#fffba7" },
              { id: 1, name: "", color: "#fffba7" },
              { id: 1, name: "", color: "#fffba7" },
              { id: 1, name: "", color: "#fffba7" },
              { id: 1, name: "", color: "#fffba7" },
              { id: 1, name: "", color: "#fffba7" },
              { id: 1, name: "", color: "#fffba7" },
              { id: 1, name: "", color: "#fffba7" },
              // { id: 1, name: " ", color: "grey" },
              // { id: 1, name: " ", color: "grey" },
              // { id: 1, name: " ", color: "grey" },
              // { id: 1, name: " ", color: "grey" },
            ],
          },
        ];
        this.tableHeaderData3 = [
          {
            columns: [
              { id: 0, name: "Week1", color: "#fffba7", fontColor: "#000" },
              { id: 0, name: "Week2", color: "#fffba7", fontColor: "#000" },
              { id: 0, name: "Week3", color: "#fffba7", fontColor: "#000" },
              { id: 0, name: "Week4", color: "#fffba7", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#fffba7", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#fffba7", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#fffba7", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#fffba7", fontColor: "#000" },
              // { id: 0, name: "Week5", color: "#313131" },
              // { id: 0, name: "Week5", color: "#313131" },
              // { id: 0, name: "Week5", color: "#313131" },
              // { id: 0, name: "Week5", color: "#313131" },
            ],
          },
        ];
        return;
      }
      this.carouselData3 = this.cda[this.count];
      this.carouselData3Color = this.cdac[this.count];
      this.upcommingProgramCustomData2 = this.upcd2[this.count];
      this.tableHeaderData3 = this.tbhd3[this.count];
    },
    handleBackDataChange() {
      // console.log("this is in handle back data change  ");
      this.backCount += 1;
      // console.log(this.backCount);
      if (this.backCount > this.cda.length) {
        this.backCount = 1;
        this.carouselData3 = "Week 1";
        this.carouselData3Color = "#fffba7";
        this.upcommingProgramCustomData2 = [
          {
            columns: [
              { id: 0, name: "1", color: "#fffba7" },
              { id: 0, name: "1", color: "#fffba7" },
              { id: 0, name: "1", color: "#fffba7" },
              { id: 0, name: "1", color: "#fffba7" },
              { id: 0, name: "1", color: "#fffba7" },
              { id: 0, name: "1", color: "#fffba7" },
              { id: 0, name: "1", color: "#fffba7" },
              { id: 0, name: "1", color: "#fffba7" },
              // { id: 0, name: " ", color: "grey" },
              // { id: 0, name: " ", color: "grey" },
              // { id: 0, name: " ", color: "grey" },
              // { id: 0, name: " ", color: "grey" },
            ],
          },
          {
            columns: [
              { id: 1, name: "1", color: "#fffba7" },
              { id: 1, name: "1", color: "#fffba7" },
              { id: 1, name: "1", color: "#fffba7" },
              { id: 1, name: "1", color: "#fffba7" },
              { id: 1, name: "1", color: "#fffba7" },
              { id: 1, name: "1", color: "#fffba7" },
              { id: 1, name: "1", color: "#fffba7" },
              { id: 1, name: "1", color: "#fffba7" },
              // { id: 1, name: " ", color: "grey" },
              // { id: 1, name: " ", color: "grey" },
              // { id: 1, name: " ", color: "grey" },
              // { id: 1, name: " ", color: "grey" },
            ],
          },
        ];
        return;
      }
      this.carouselData3 = this.cda[this.cda.length - this.backCount];
      this.carouselData3Color = this.cdac[this.cdac.length - this.backCount];
      this.upcommingProgramCustomData2 = this.upcd2[this.upcd2.length - this.backCount];
    },
    handleDropdownChange(e) {
      this.selectedOutputOption = e;
      // console.log(e);
      switch (e.id) {
        case 0:
          this.showBlock = "screen_1";
          break;
        case 1:
          this.showBlock = "screen_2";
          break;
        case 2:
          // this.showBlock = "screen_3";
          break;
        case 3:
          this.showBlock = "screen_4";
          break;
        case 4:
          this.showBlock = "screen_5";
          break;
        default:
          this.showBlock = "screen_1";
          break;
      }
    },
    getAPIData() {
      this.otherProgramTableData = [];
      let runId = sessionStorage.getItem("run_id");
      let clientId = sessionStorage.getItem("clientId");
      let planName = sessionStorage.getItem("SelectACDPlanName");
      zeeSolutions
        .getMonthlyOutput(runId, 1)
        .then((res) => {
          // console.log(res.promodata);
          this.apiData = res.promodata;
          this.upcommingProgramData[0].columns[0].name = this.apiData.programName;
          this.upcommingProgramData[0].columns[1].name = this.apiData.programLaunchDate;
          this.upcommingProgramData[0].columns[2].name = this.apiData.programGenre;
          this.upcommingProgramData[0].columns[3].name = this.apiData.priority;
          this.upcommingProgramData[0].columns[4].name = this.apiData.grpTarget;
          this.upcommingProgramCustomData[0].columns[0].name = this.apiData[
            "0"
          ].secondages;
          this.upcommingProgramCustomData[0].columns[1].name = Math.round(
            +this.apiData["0"].assignedGRP
          );
          this.upcommingProgramCustomData[0].columns[2].name = this.apiData[
            "0"
          ].grpTarget;
          let promoDataKeys = Object.keys(this.apiData);
          // console.log("In the getAPIData" + promoDataKeys);
          for (let i = 1; i < promoDataKeys.length - 5; i++) {
            this.upcd.push([
              {
                columns: [
                  { id: 0, name: "700", color: this.cdac[i] },
                  { id: 0, name: "1.5", color: this.cdac[i] },
                  { id: 0, name: "100", color: this.cdac[i] },
                ],
              },
            ]);

            this.upcd[i - 1][0].columns[0].name = this.apiData[
              promoDataKeys[i]
            ].secondages;
            this.upcd[i - 1][0].columns[1].name = Math.round(
              +this.apiData[promoDataKeys[i]].assignedGRP
            );
            this.upcd[i - 1][0].columns[2].name = this.apiData[
              promoDataKeys[i]
            ].grpTarget;
            this.numberOfWeeks += 1;
            this.tbhd.push([
              {
                columns: [
                  { id: 0, name: "Secondage", color: this.cdac[i] },
                  { id: 0, name: "Assigned GRPs", color: this.cdac[i] },
                  { id: 0, name: "Planned GRPs", color: this.cdac[i] },
                ],
              },
            ]);
          }
          this.otherProgramsData = res.otherData;
          // console.log("length of otherprogamdata" + this.otherProgramsData)
          for (let j = 0; j < this.otherProgramsData.length; j++) {
            let opcd = [
              {
                columns: [
                  { id: 0, name: "" },
                  { id: 1, name: "" },
                  { id: 2, name: "" },
                  { id: 3, name: "" },
                  { id: 4, name: "" },
                ],
              },
            ];
            opcd[0].columns[0].name = this.otherProgramsData[j].programName;
            opcd[0].columns[1].name = this.otherProgramsData[j].programLaunchDate;
            opcd[0].columns[2].name = this.otherProgramsData[j].programGenre;
            opcd[0].columns[3].name = this.otherProgramsData[j].priority;
            opcd[0].columns[4].name = this.otherProgramsData[j].grpTarget;
            // console.log(opcd);
            this.otherProgramTableData.push(opcd);
            // console.log(j)
          }
          // console.log(this.otherProgramsData);
          for (let i = 0; i < this.otherProgramsData.length; i++) {
            this.otherProgramTableBodyData[i] = [
              {
                columns: [
                  {
                    id: 0,
                    name: this.otherProgramsData[i][0].secondages,
                    color: "#fffba7",
                  },
                  {
                    id: 1,
                    name: Math.round(+this.otherProgramsData[i][0].assignedGRP),
                    color: "#fffba7",
                  },
                  {
                    id: 2,
                    name: this.otherProgramsData[i][0].grpTarget,
                    color: "#fffba7",
                  },
                ],
              },
            ];
          }
          for (let i = 0; i < this.otherProgramsData.length; i++) {
            this.opcdb.push([]);
            // console.log("llll" + Object.keys(this.otherProgramsData[i]).length);
            for (let j = 1; j < Object.keys(this.otherProgramsData[i]).length - 5; j++) {
              this.opcdb[i].push([
                {
                  columns: [
                    {
                      id: 0,
                      name: this.otherProgramsData[i][j].secondages,
                      color: this.cdac[j - 1],
                    },
                    {
                      id: 1,
                      name: Math.round(+this.otherProgramsData[i][j].assignedGRP),
                      color: this.cdac[j - 1],
                    },
                    {
                      id: 2,
                      name: this.otherProgramsData[i][j].grpTarget,
                      color: this.cdac[j - 1],
                    },
                  ],
                },
              ]);
            }

            // console.log("number of weeks", this.numberOfWeeks);
          }
          // for(let i = 0; i<)
          // let colors = "#fffba7";
          // // console.("res", res, this.backCount);
          // let data = res.promo[1]["1"];
          // this.upcommingProgramCustomData[0].columns = [];

          // for (const key in data) {
          //   this.upcommingProgramCustomData[0].columns.push({
          //     name: data[key],
          //     color: colors,
          //   });
          //   // this.upcommingProgramCustomData[0].columns.push({ name: data[0]["1"][key] });

          //   console.log(data[key]);
          // }

          // let promoData = res.promo[0];

          // this.upcommingProgramData[0].columns = [];

          // for (const key in promoData) {
          //   console.log(promoData[key]);
          //   this.upcommingProgramData[0].columns.push({
          //     name: promoData[key],
          //     color: colors,
          //   });
          // }
        })
        .then(() => {
          if (planName != null) {
            zeeSolutions
              .saveFinalDetails(runId, clientId, planName)
              .then(() => {
                console.log("");
              })
              .catch((err) => {
                alert(err);
              });
          }
        })
        .catch(() => {
          this.$router.push("/solutions/zeetv/program-output")
        });
      zeeSolutions
        .getWeeklySummaryOuput(runId)
        .then((res) => {
          // console.log(res);
          this.totalSecondagesWeekly = res.pop().reverse();
          let weeklySummaryData = res;
          // console.log(weeklySummaryData)
          let firstWeekNum = Object.keys(weeklySummaryData[0])[0];
          for (let i = 0; i < weeklySummaryData[0][firstWeekNum].length; i++) {
            let upd2 = {
              columns: [
                { id: 0, name: "" },
                { id: 1, name: "" },
              ],
            };
            // console.log()
            upd2.columns[0].name = weeklySummaryData[0][firstWeekNum][i].programName;
            upd2.columns[1].name =
              weeklySummaryData[0][firstWeekNum][i].programLaunchDate;
            this.upcommingProgramData2.push(upd2);
          }
          for (let j = weeklySummaryData.length - 1; j >= 0; j--) {
            let firstWeekNum = Object.keys(weeklySummaryData[j])[0];
            // console.log(weeklySummaryData[j][firstWeekNum][0].startDate)
            let tbh2 = {
              id: j,
              name: `Week ${firstWeekNum}`,
              color: "#313131",
              fontColor: "#fff",
              startDate: weeklySummaryData[j][firstWeekNum][0].startDate,
              endDate: weeklySummaryData[j][firstWeekNum][0].endDate,
              numOfDays: weeklySummaryData[j][firstWeekNum][0].numberofdays,
            };
            this.tableHeaderData2[0].columns.push(tbh2);
          }
          for (let k = 0; k < weeklySummaryData[0][firstWeekNum].length; k++) {
            let upcd1 = {
              columns: [],
            };
            // console.log(weeklySummaryData[0][firstWeekNum])
            for (let g = weeklySummaryData.length - 1; g >= 0; g--) {
              let firstWeekNumInside = Object.keys(weeklySummaryData[g])[0];
              // console.log("in the weekly",weeklySummaryData[g][`${g}`][k].secondage)
              let upcd1Temp = {
                id: k,
                name: `${weeklySummaryData[g][firstWeekNumInside][k].secondage}`,
                color: "grey",
              };
              upcd1.columns.push(upcd1Temp);
            }
            this.upcommingProgramCustomData1.push(upcd1);
            // console.log(upcd1);
          }
          // console.log(this.upcommingProgramCustomData1);
          // for(let f = 0; f<weeklySummaryData.length; f++){
          //   let sum = 0
          //   for(let s = 0; s<weeklySummaryData[f][`${f}`].length; s++){
          //     sum+=weeklySummaryData[f][`${f}`][s].secondage
          //   }
          //   this.totalSecondagesWeekly.push(sum)
          // }
        })
        .catch(() => {
          this.$router.push("/solutions/zeetv/program-output")

        });
    },
  },
  data() {
    return {
      toastData: {
        show: false,
        type: "",
        message: "",
      },
      programOutputData: {
        columns: [
          {
            planName: "Monthly Summary",
          },
          {
            planName: "Weekly Summary",
          },
          {
            planName: "Date Wise Summary",
          },
          {
            planName: "Daily Schedule",
          },
        ],
      },
      showProgramOutputDownloadModal: false,
      outputSummaryPraramData: "",
      totalSecondagesWeekly: [],
      apiData: {},
      otherProgramsData: [],
      numberOfWeeks: 1,
      selectedOutputOption: { text: "Weekly Summary", id: 1 },
      tableBodyWidth: 0,
      count1: -1,
      count: -1,
      cda: ["Week 2", "Week 3", "Week 4"],
      cdac: [
        "#ff94b7",
        "#ffc107",
        "#6db056",
        "#ff94b7",
        "#ffc107",
        "#6db056",
        "#ff94b7",
        "#ffc107",
      ],
      backCount: 1,
      showBlock: "screen_2",
      // carouselData3:[
      //   {name:'Week 1', color:'#fffba7'}
      //   ],
      carouselData1: "Week 1",
      carouselData1Color: "#fffba7",
      carouselData3: "Week 1",
      carouselData3Color: "#fffba7",
      // carouselData5:[
      //   {name:'01-06-2022', color:'#6db056'},
      //   {name:'01-06-2022', color:'#6db056'},
      //   {name:'01-06-2022', color:'#6db056'},
      //   ],
      carouselData5: ["01-06-2022", "01-06-2022", "01-06-2022"],
      // topHeader : ['01-06-2022 PT','01-06-2022 PT','01-06-2022 PT','01-06-2022 PT'],
      topHeader: ["01-06-2022 PT"],
      upcd2: [
        [
          {
            columns: [
              { id: 0, name: "", color: "#ff94b7" },
              { id: 0, name: "", color: "#ff94b7" },
              { id: 0, name: "", color: "#ff94b7" },
              { id: 0, name: "", color: "#ff94b7" },
              { id: 0, name: "", color: "#ff94b7" },
              { id: 0, name: "", color: "#ff94b7" },
              { id: 0, name: "", color: "#ff94b7" },
              { id: 0, name: "", color: "#ff94b7" },
              // { id: 0, name: " ", color: "grey" },
              // { id: 0, name: " ", color: "grey" },
              // { id: 0, name: " ", color: "grey" },
              // { id: 0, name: " ", color: "grey" },
            ],
          },
          {
            columns: [
              { id: 1, name: "", color: "#ff94b7" },
              { id: 1, name: "", color: "#ff94b7" },
              { id: 1, name: "", color: "#ff94b7" },
              { id: 1, name: "", color: "#ff94b7" },
              { id: 1, name: "", color: "#ff94b7" },
              { id: 1, name: "", color: "#ff94b7" },
              { id: 1, name: "", color: "#ff94b7" },
              { id: 1, name: "", color: "#ff94b7" },
              // { id: 1, name: " ", color: "grey" },
              // { id: 1, name: " ", color: "grey" },
              // { id: 1, name: " ", color: "grey" },
              // { id: 1, name: " ", color: "grey" },
            ],
          },
        ],
        [
          {
            columns: [
              { id: 0, name: "", color: "#ffc107" },
              { id: 0, name: "", color: "#ffc107" },
              { id: 0, name: "", color: "#ffc107" },
              { id: 0, name: "", color: "#ffc107" },
              { id: 0, name: "", color: "#ffc107" },
              { id: 0, name: "", color: "#ffc107" },
              { id: 0, name: "", color: "#ffc107" },
              { id: 0, name: "", color: "#ffc107" },
              // { id: 0, name: " ", color: "grey" },
              // { id: 0, name: " ", color: "grey" },
              // { id: 0, name: " ", color: "grey" },
              // { id: 0, name: " ", color: "grey" },
            ],
          },
          {
            columns: [
              { id: 1, name: "", color: "#ffc107" },
              { id: 1, name: "", color: "#ffc107" },
              { id: 1, name: "", color: "#ffc107" },
              { id: 1, name: "", color: "#ffc107" },
              { id: 1, name: "", color: "#ffc107" },
              { id: 1, name: "", color: "#ffc107" },
              { id: 1, name: "", color: "#ffc107" },
              { id: 1, name: "", color: "#ffc107" },
              // { id: 1, name: " ", color: "grey" },
              // { id: 1, name: " ", color: "grey" },
              // { id: 1, name: " ", color: "grey" },
              // { id: 1, name: " ", color: "grey" },
            ],
          },
        ],
        [
          {
            columns: [
              { id: 0, name: "", color: "#6db056" },
              { id: 0, name: "", color: "#6db056" },
              { id: 0, name: "", color: "#6db056" },
              { id: 0, name: "", color: "#6db056" },
              { id: 0, name: "", color: "#6db056" },
              { id: 0, name: "", color: "#6db056" },
              { id: 0, name: "", color: "#6db056" },
              { id: 0, name: "", color: "#6db056" },
              // { id: 0, name: " ", color: "grey" },
              // { id: 0, name: " ", color: "grey" },
              // { id: 0, name: " ", color: "grey" },
              // { id: 0, name: " ", color: "grey" },
            ],
          },
          {
            columns: [
              { id: 1, name: "", color: "#6db056" },
              { id: 1, name: "", color: "#6db056" },
              { id: 1, name: "", color: "#6db056" },
              { id: 1, name: "", color: "#6db056" },
              { id: 1, name: "", color: "#6db056" },
              { id: 1, name: "", color: "#6db056" },
              { id: 1, name: "", color: "#6db056" },
              { id: 1, name: "", color: "#6db056" },
              // { id: 1, name: " ", color: "grey" },
              // { id: 1, name: " ", color: "grey" },
              // { id: 1, name: " ", color: "grey" },
              // { id: 1, name: " ", color: "grey" },
            ],
          },
        ],
      ],
      upcd: [
        [
          {
            columns: [
              { id: 0, name: "700", color: "#ff94b7" },
              { id: 0, name: "1.5", color: "#ff94b7" },
              { id: 0, name: "100", color: "#ff94b7" },
            ],
          },
        ],
        // [
        //   {
        //     columns: [
        //       { id: 0, name: "800", color: "#ffc107" },
        //       { id: 0, name: "2.5", color: "#ffc107" },
        //       { id: 0, name: "200", color: "#ffc107" },
        //     ],
        //   },
        // ],
        // [
        //   {
        //     columns: [
        //       { id: 0, name: "650", color: "#6db056" },
        //       { id: 0, name: "0.5", color: "#6db056" },
        //       { id: 0, name: "50", color: "#6db056" },
        //     ],
        //   },
        // ],
      ],
      tbhd: [
        [
          {
            columns: [
              { id: 0, name: "Secondage", color: "#ff94b7" },
              { id: 0, name: "Assigned GRPs", color: "#ff94b7" },
              { id: 0, name: "Planned GRPs", color: "#ff94b7" },
            ],
          },
        ],
      ],
      tbhd3: [
        [
          {
            columns: [
              { id: 0, name: "Week1", color: "#ff94b7", fontColor: "#000" },
              { id: 0, name: "Week2", color: "#ff94b7", fontColor: "#000" },
              { id: 0, name: "Week3", color: "#ff94b7", fontColor: "#000" },
              { id: 0, name: "Week4", color: "#ff94b7", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#ff94b7", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#ff94b7", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#ff94b7", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#ff94b7", fontColor: "#000" },
              // { id: 0, name: "Week5", color: "#313131" },
              // { id: 0, name: "Week5", color: "#313131" },
              // { id: 0, name: "Week5", color: "#313131" },
              // { id: 0, name: "Week5", color: "#313131" },
            ],
          },
        ],
        [
          {
            columns: [
              { id: 0, name: "Week1", color: "#ffc107", fontColor: "#000" },
              { id: 0, name: "Week2", color: "#ffc107", fontColor: "#000" },
              { id: 0, name: "Week3", color: "#ffc107", fontColor: "#000" },
              { id: 0, name: "Week4", color: "#ffc107", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#ffc107", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#ffc107", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#ffc107", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#ffc107", fontColor: "#000" },
              // { id: 0, name: "Week5", color: "#313131" },
              // { id: 0, name: "Week5", color: "#313131" },
              // { id: 0, name: "Week5", color: "#313131" },
              // { id: 0, name: "Week5", color: "#313131" },
            ],
          },
        ],
        [
          {
            columns: [
              { id: 0, name: "Week1", color: "#6db056", fontColor: "#000" },
              { id: 0, name: "Week2", color: "#6db056", fontColor: "#000" },
              { id: 0, name: "Week3", color: "#6db056", fontColor: "#000" },
              { id: 0, name: "Week4", color: "#6db056", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#6db056", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#6db056", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#6db056", fontColor: "#000" },
              { id: 0, name: "Week5", color: "#6db056", fontColor: "#000" },
              // { id: 0, name: "Week5", color: "#313131" },
              // { id: 0, name: "Week5", color: "#313131" },
              // { id: 0, name: "Week5", color: "#313131" },
              // { id: 0, name: "Week5", color: "#313131" },
            ],
          },
        ],
      ],
      mainHeader: [
        {
          columns: [
            { id: 1, name: "Program Name" },
            { id: 2, name: "Date of Launch" },
            { id: 3, name: "Program Genre" },
            { id: 4, name: "Priority" },
            { id: 5, name: "Target GRPs" },
          ],
        },
      ],
      mainHeader2: [
        {
          columns: [
            { id: 0, name: "Program Name", color: "#313131", fontColor: "#ffffff" },
            { id: 0, name: "Date of Launch", color: "#313131", fontColor: "#ffffff" },
          ],
        },
      ],
      tableHeaderData: [
        {
          columns: [
            { id: 0, name: "Secondage", color: "#fffba7" },
            { id: 0, name: "Assigned GRPs", color: "#fffba7" },
            { id: 0, name: "Planned GRPs", color: "#fffba7" },
          ],
        },
      ],
      tableHeaderData2: [
        {
          columns: [],
        },
      ],
      tableHeaderData3: [
        {
          columns: [
            { id: 0, name: "Week1", color: "#fffba7", fontColor: "#000" },
            { id: 0, name: "Week2", color: "#fffba7", fontColor: "#000" },
            { id: 0, name: "Week3", color: "#fffba7", fontColor: "#000" },
            { id: 0, name: "Week4", color: "#fffba7", fontColor: "#000" },
            { id: 0, name: "Week5", color: "#fffba7", fontColor: "#000" },
            { id: 0, name: "Week5", color: "#fffba7", fontColor: "#000" },
            { id: 0, name: "Week5", color: "#fffba7", fontColor: "#000" },
            { id: 0, name: "Week5", color: "#fffba7", fontColor: "#000" },
            // { id: 0, name: "Week5", color: "#313131" },
            // { id: 0, name: "Week5", color: "#313131" },
            // { id: 0, name: "Week5", color: "#313131" },
            // { id: 0, name: "Week5", color: "#313131" },
          ],
        },
      ],
      tableHeaderData4: [
        {
          columns: [
            { id: 0, name: "Week1", color: "#ffc107", fontColor: "#000" },
            { id: 0, name: "Week2", color: "#ffc107", fontColor: "#000" },
            // { id: 0, name: "Week3", color: "#ffc107", fontColor:"#000"  },
            // { id: 0, name: "Week4", color: "#ffc107", fontColor:"#000"  },
            // { id: 0, name: "Week5", color: "#ffc107", fontColor:"#000"  },
            // { id: 0, name: "Week5", color: "#ffc107", fontColor:"#000"  },
            // { id: 0, name: "Week5", color: "#ffc107", fontColor:"#000"  },
            // { id: 0, name: "Week5", color: "#ffc107", fontColor:"#000"  },
            // { id: 0, name: "Week5", color: "#313131" },
            // { id: 0, name: "Week5", color: "#313131" },
            // { id: 0, name: "Week5", color: "#313131" },
            // { id: 0, name: "Week5", color: "#313131" },
          ],
        },
      ],
      tableHeaderData5: [
        {
          columns: [
            { id: 0, name: "Week1", color: "#ffc107", fontColor: "#000000" },
            { id: 0, name: "Week2", color: "#ffc107", fontColor: "#000000" },
            { id: 0, name: "Week3", color: "#ffc107", fontColor: "#000000" },
          ],
        },
      ],
      upcommingProgramCustomData: [
        {
          columns: [
            { id: 0, name: "650", color: "#fffba7" },
            { id: 0, name: "0.5", color: "#fffba7" },
            { id: 0, name: "50", color: "#fffba7" },
          ],
        },
      ],
      upcommingProgramCustomData1: [],
      upcommingProgramCustomData4: [
        {
          columns: [
            { id: 0, name: " ", color: "#6db056" },
            { id: 0, name: " ", color: "#6db056" },
            { id: 0, name: " ", color: "#6db056" },
            { id: 0, name: " ", color: "#6db056" },
            { id: 0, name: " ", color: "#6db056" },
            { id: 0, name: " ", color: "#6db056" },
            { id: 0, name: " ", color: "#6db056" },
            { id: 0, name: " ", color: "#6db056" },
            // { id: 0, name: " ", color: "grey" },
            // { id: 0, name: " ", color: "grey" },
            // { id: 0, name: " ", color: "grey" },
            // { id: 0, name: " ", color: "grey" },
          ],
        },
        {
          columns: [
            { id: 1, name: " ", color: "#6db056" },
            { id: 1, name: " ", color: "#6db056" },
            { id: 1, name: " ", color: "#6db056" },
            { id: 1, name: " ", color: "#6db056" },
            { id: 1, name: " ", color: "#6db056" },
            { id: 1, name: " ", color: "#6db056" },
            { id: 1, name: " ", color: "#6db056" },
            { id: 1, name: " ", color: "#6db056" },
            // { id: 1, name: " ", color: "grey" },
            // { id: 1, name: " ", color: "grey" },
            // { id: 1, name: " ", color: "grey" },
            // { id: 1, name: " ", color: "grey" },
          ],
        },
      ],
      upcommingProgramCustomData2: [
        {
          columns: [
            { id: 0, name: "", color: "#fffba7" },
            { id: 0, name: "", color: "#fffba7" },
            { id: 0, name: "", color: "#fffba7" },
            { id: 0, name: "", color: "#fffba7" },
            { id: 0, name: "", color: "#fffba7" },
            { id: 0, name: "", color: "#fffba7" },
            { id: 0, name: "", color: "#fffba7" },
            { id: 0, name: "", color: "#fffba7" },
            // { id: 0, name: " ", color: "grey" },
            // { id: 0, name: " ", color: "grey" },
            // { id: 0, name: " ", color: "grey" },
            // { id: 0, name: " ", color: "grey" },
          ],
        },
        {
          columns: [
            { id: 1, name: "", color: "#fffba7" },
            { id: 1, name: "", color: "#fffba7" },
            { id: 1, name: "", color: "#fffba7" },
            { id: 1, name: "", color: "#fffba7" },
            { id: 1, name: "", color: "#fffba7" },
            { id: 1, name: "", color: "#fffba7" },
            { id: 1, name: "", color: "#fffba7" },
            { id: 1, name: "", color: "#fffba7" },
            // { id: 1, name: " ", color: "grey" },
            // { id: 1, name: " ", color: "grey" },
            // { id: 1, name: " ", color: "grey" },
            // { id: 1, name: " ", color: "grey" },
          ],
        },
      ],
      upcommingProgramCustomData5: [
        [
          [
            {
              columns: [
                { id: 0, name: " ", color: "#6db056" },
                { id: 0, name: " ", color: "#6db056" },
                { id: 0, name: " ", color: "#6db056" },
              ],
            },
          ],
          [
            {
              columns: [
                { id: 0, name: " ", color: "#6db056" },
                { id: 0, name: " ", color: "#6db056" },
                { id: 0, name: " ", color: "#6db056" },
              ],
            },
          ],
        ],
        [
          [
            {
              columns: [
                { id: 0, name: " ", color: "#6db056" },
                { id: 0, name: " ", color: "#6db056" },
                { id: 0, name: " ", color: "#6db056" },
              ],
            },
          ],
          [
            {
              columns: [
                { id: 0, name: " ", color: "#6db056" },
                { id: 0, name: " ", color: "#6db056" },
                { id: 0, name: " ", color: "#6db056" },
              ],
            },
          ],
        ],
        [
          [
            {
              columns: [
                { id: 0, name: " ", color: "#6db056" },
                { id: 0, name: " ", color: "#6db056" },
                { id: 0, name: " ", color: "#6db056" },
              ],
            },
          ],
          [
            {
              columns: [
                { id: 0, name: " ", color: "#6db056" },
                { id: 0, name: " ", color: "#6db056" },
                { id: 0, name: " ", color: "#6db056" },
              ],
            },
          ],
        ],
      ],
      tableHeaderData1: [
        {
          columns: [
            { id: 0, name: "650", color: "green" },
            { id: 0, name: "0.5", color: "green" },
            { id: 0, name: "50", color: "green" },
          ],
        },
      ],
      tableBodyData: [
        {
          columns: [
            { id: 0, name: "program Name", color: "green" },
            { id: 0, name: "program Name", color: "green" },
            { id: 0, name: "program Name", color: "green" },
          ],
        },
        {
          columns: [
            { id: 0, name: "program Name", color: "green" },
            { id: 0, name: "program Name", color: "green" },
            { id: 0, name: "program Name", color: "green" },
          ],
        },
        {
          columns: [
            { id: 0, name: "program Name", color: "green" },
            { id: 0, name: "program Name", color: "green" },
            { id: 0, name: "program Name", color: "green" },
          ],
        },
      ],
      outputDropdown: [
        // { text: "Monthly Summary", id: 0 },
        { text: "Weekly Summary", id: 1 },
        // { text: "Weekly Detailed Plan", id: 2 },
        // { text: "Date Wise Summary", id: 3 },
        // { text: "Daily Schedule", id: 4 },
      ],
      upcommingProgramData: [
        {
          columns: [
            { id: 0, name: "" },
            { id: 1, name: "" },
            { id: 2, name: "" },
            { id: 3, name: "" },
            { id: 4, name: "" },
          ],
        },
      ],
      otherProgramTableData: [],
      otherProgramTableBodyData: [
        [
          {
            columns: [
              { id: 0, name: "650", color: "#fffba7" },
              { id: 0, name: "0.5", color: "#fffba7" },
              { id: 0, name: "50", color: "#fffba7" },
            ],
          },
        ],
      ],
      opcdb: [],
      upcommingProgramData2: [],
    };
  },
};
</script>

<style>
.zee-output-program-container {
  margin-top: 38px;
  overflow-x: auto;
}

.zee-output-program-container::-webkit-scrollbar {
  display: none;
}

.zee-output-program-table {
  font-size: 16px;
  font-weight: bold;
}

.zee-output-program-table tbody {
  padding-top: 32px;
}

.zee-output-program-table th,
.zee-output-program-table td {
  padding: 8px;
}

.zee-output-program-table-header,
.zee-output-program-table-body,
.zee-output-program-table-btn {
  width: 160px;
  display: inline-block;
  background-color: #313131;
  color: #fff;
  padding: 6px 12px;
  text-align: center;
  font-size: 14px;
  border-radius: 8px;
  border: none;
  text-align: center;
}

.zee-output-program-table-header {
  background-color: #6c757d;
}

.zee-output-program-table-btn {
  background-color: #6db056;
}

.zee-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align horizontal */
  align-items: center;
  /* align vertical */
  height: 100%;
}

.zee-container-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /*align horizontal*/
  align-items: center;
  /*align vertical */
  margin-top: 3%;
  /* height: 100%;
  width: 100%; */
}

.zee-card-background {
  padding: 3rem;
  background-color: #fafafa;
  width: 90%;
  margin-top: 18px;
  border-radius: 15px;
}

.zee-output-table-align {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  /* gap: 23.5rem; */
  /* margin-top: 18px; */
  /* border-radius: 8px;
  background-color: #f3f3f3; */
}

.zee-table-card-background {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* gap:23.5rem; */
  margin-top: 18px;
  border-radius: 8px;
  background-color: #f3f3f3;
}

.zee-output-summary-tab {
  /* width: calc(63% + 100px); */
  background-color: #6c757d;
  height: 25px;
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  display: flex;
}

.zee-output-summary-tab-total {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  background-color: #313131;
  width: 10.5%;
  height: 25px;
  border-radius: 8px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  color: #ffffff;
}

.zee-output-table-buttons {
  display: flex;
  justify-content: space-between;
}

.zee-table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.zee-table-header-data {
  width: 20%;
  background-color: #6db056;
  height: 25px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 130%;
  padding: 0.4% 0.4% 0.4% 0.4%;
}

.zee-totalContentWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  width: 100%;
  font-weight: bold;
  font-size: medium;
}

.zee-totalContent {
  display: flex;
  justify-content: center;
  width: 148px;
  align-items: center;
  margin-left: 1rem;
  margin-right: 1rem;
}
</style>
